import { getBookingsAutomationSettings } from '@wix/ambassador-bookings-v1-bookings-automation-settings/http';
import { FormApiContext } from '../utils';
import {
  BookingsAutomationComponentIds,
  BookingsAutomationStatus,
} from '@wix/ambassador-bookings-v1-bookings-automation-settings/types';

export const isSMSAutomationsEnabled = async ({
  httpClient,
}: FormApiContext): Promise<boolean> => {
  try {
    const { data } = await httpClient.request(
      getBookingsAutomationSettings({
        automationKeys: [BookingsAutomationComponentIds.SMS_UPCOMING_REMINDER],
      }),
    );

    return (
      data.automationSettings?.find(
        (settings) =>
          settings.automationKey ===
          BookingsAutomationComponentIds.SMS_UPCOMING_REMINDER,
      )?.status === BookingsAutomationStatus.ACTIVE
    );
  } catch (e) {
    console.error('Failed to call getBookingsAutomationSettings', e);
    return true;
  }
};
